/*
 * @Author: ruiqiwu
 * @Date: 2023-02-27 16:18:27
 * @LastEditors: v_qiawwu
 * @LastEditTime: 2023-03-10 11:22:06
 * @FilePath: \cqwh\src\utils\mta\mta.js
 * @Description:mta 上报函数
 */
import MtaH5 from '@tencent/fdt-mta-bi';
import { getSystem } from './systemInfo';
import { mtaConfig } from '@/utils/mta/mta.config';

/**
 * @description: mta 初始化
 * @return {*}
 */
export const mtaInit = () => {
  MtaH5.init({
    sid: mtaConfig.sid, // 必填，统计用的appid
    cid: mtaConfig.cid, // 如果开启自定义事件，此项目为必填，否则不填
    autoReport: 0, // 是否开启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
    senseHash: 0, // hash锚点是否进入url统计
    senseQuery: 0, // url参数是否进入url统计
    performanceMonitor: 0, // 是否开启性能监控
    ignoreParams: [], // 开启url参数上报时，可忽略部分参数拼接上报
  });
};

/**
 * @description: 获取用户唯一ID
 * @return {*}
 */
export const getUid = () => {
  // 某些情况下操作 localStorage 会报错.
  try {
    let uid = localStorage.getItem('MAT_ID');
    if (!uid) {
      uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      localStorage.setItem('MAT_ID', uid);
    }
    return uid;
  } catch (e) {
    return '';
  }
};

export const MTA_MENU_ID = {
  home: 1,
  education: 2,
  info: 3,
  about: 4,
};

export const mtaReport = (eventName, menuId, fevt = 'click') => {
  MtaH5.clickStatBI(eventName, {
    // 默认收集下面五项上报，可重置
    furl: location.href,
    fuin: getUid(),
    fevent_time: new Date().getTime(),
    fos: getSystem(),
    fevt, // 页面曝光事件为 brow
    fsystem_code: mtaConfig.fsystemCode,
    menu_id: menuId,
  });
};
